import type { THookUseNav, IUseNav, IPageProfile, IRouterParam, IRouterPushParams } from '@/types/hook/useNav';
import { Toaster } from '@/util/uiKit/toaster';
import { ref, computed, onMounted, nextTick } from 'vue';
import { isNavigationFailure, useRouter, type NavigationFailure, type RouteLocationNormalized, type RouteLocationRaw, type HistoryState, type Router } from 'vue-router';
import type { IMenuItem, TCrudState, TMenuGoup } from 'shangshi_types';
import { getCompareParameStr, getStateByKey, identToNavName } from '@/util/routerUtil';
import { HOME_KEY, KEY_STORE_NAV } from '@/constant/sessionKey';
import { useCache } from '@/hook';
import { ERR_MSG } from '@/constant/err';
import type { TCloseDirction } from '@/types/store/useApp';
import type { IUseCache } from '@/types/hook/useCache';

const shareObj: { routerMgr: null | Router, cacheMgr: null | IUseCache } = {
  routerMgr: null,
  cacheMgr: null
};

const shareRouter = (): Router => {
  if (!shareObj.routerMgr) {
    shareObj.routerMgr = useRouter();
  }
  return shareObj.routerMgr;
};

const shareCache = (): IUseCache => {
  if (!shareObj.cacheMgr) {
    shareObj.cacheMgr = useCache();
  }
  return shareObj.cacheMgr;
};

const STATE_NAME: Partial<Record<TCrudState, string>> = {
  add: '新增',
  edit: '修改',
  view: '查看'
};

const HOME_KEY_LOW = HOME_KEY.toLowerCase();
/** 判断导航目标是否为首页 */
const isHomePage = (opt: { key?: string, name?: string, path?: string }) => ((opt.key)?.toLowerCase() === HOME_KEY_LOW)
  || ((opt.name)?.toLowerCase() === HOME_KEY_LOW)
  || (opt.path === '/');

const getTagName = (to: RouteLocationNormalized, from: RouteLocationNormalized, params?: HistoryState): { namezh: string, btns: string[] } => {
  const { bizIdent: bizIdentTo } = to.meta;
  const { namezh: namezhTo, btns = [] } = shareCache().getMenus()[bizIdentTo as string] || {};
  const arr: string[] = [];
  if (namezhTo) {
    const { cloneId, pushParam } = params || {};
    if (pushParam) { // 若为下推
      const { bizIdent: bizIdentFrom } = from.meta;
      const { namezh: namezhFrom } = shareCache().getMenus()[bizIdentFrom as string] || {};
      arr.push(namezhFrom, '->');
    }
    arr.push(namezhTo);
    const crudState = getStateByKey(to.name as string);
    const stateName = STATE_NAME[crudState];
    if (stateName) {
      if (+(cloneId || -1) > 0) { // 若为复制
        arr.push('(复制新增)');
      } else {
        arr.push(`(${stateName})`);
      }
    }
  }
  return { namezh: arr.join(''), btns };
};

const openPages = ref<Map<string, IPageProfile>>(new Map());
const activeKey = ref<string>('');
/** 是否已经执行过缓存 */
const cacheDone = ref<boolean>(false);

/** 获取由已打开页面缓存形成的标签数组 */
const pageList = computed(() => Array.from(openPages.value.values()));
const pageInclude = computed(() => pageList.value.map(v => v.keyTo));
/** 获取当前页面的面包屑 */
const breadCrumbs = computed(() => openPages.value.get(activeKey.value)?.breadCrumbs || []);
/** 基于 activeKey 而获取到的活动页面（可能为 undefined） */
const activePage = computed((): IPageProfile | undefined => {
  const actKey = activeKey.value;
  const page = openPages.value.get(actKey);
  if (!page) {
    Toaster.warning(ERR_MSG.stateFail);
  }
  return page;
});

export const useNav: THookUseNav = (): IUseNav => {

  /** 获取指定的页面描述信息
   *
   * @param bizIdent 目标页面的业务标识
   * @param crudState 目标页面的视图状态
   * @returns
   */
  const getPage = (bizIdent: string, crudState: TCrudState): IPageProfile | undefined => {
    const keyTo = identToNavName(bizIdent, crudState);
    const page = openPages.value.get(keyTo);
    return page;
  };

  // #region 导航控制
  /** 响应菜单触发的导航
   *
   * @param menuItem 被点击的菜单项所触发的事件载荷
   */
  const navByMenu = async (menuItem: IMenuItem): Promise<void> => {
    const keyTo = isHomePage({ key: menuItem.code }) ? HOME_KEY : menuItem.name;
    await navTo(keyTo);
  };

  /** 从序时簿的页面响应同源不同状态的编程导航
   *
   * @param stateTo 触发导航的目标状态
   * @param params 路由参数
   * @returns
   */
  const navFromList = async (stateTo: TCrudState, params?: IRouterParam): Promise<void> => {
    if (!(['list', 'del'] as TCrudState[]).includes(stateTo)) {
      const page = activePage.value;
      if (page) {
        const keyTo = identToNavName(page.bizIdent, stateTo);
        await navTo(keyTo, params);
      } // 无需 else，因为 activePage 中已包含 page 为 undefined 的处理
    }
  };

  /** 从CRUD状态的页面响应同源不同状态的编程导航
   *
   * @param stateTo 触发导航的目标状态
   * @param params 路由参数
   * @returns
   */
  const navFromCrud = async (stateTo: TCrudState, params?: IRouterParam): Promise<void> => {
    const page = activePage.value;
    if (page) {
      const keyTo = identToNavName(page.bizIdent, stateTo);
      await navTo(keyTo, params);
    } // 无需 else，因为 activePage 中已包含 page 为 undefined 的处理
  };

  /** 从CRUD状态的页面点击了保存或取消按钮后的编程导航
   *
   * @param billId 返回到查看页面时，使用的 billId 值，若为取消，则可以不提供
   */
  const navFromSave = async (keyBy: string, opt?: { stateTo: TCrudState, billId?: number }) => {
    const page = openPages.value.get(keyBy);
    if (page) {
      const destState = opt?.stateTo || page.keyFrom as TCrudState;
      const keyTo = identToNavName(page?.bizIdent, destState);
      if (opt?.billId) {
        navTo(keyTo, { billId: opt.billId });
      } else {
        navTo(keyTo);
      }
    } else {
      await goToHome();
    }

    // const key = activeKey.value;
    // const page = openPages.value.get(key);
    // if (!page) { // 如果代表当前页面的缓存失效，如手工在控制台删除过缓存
    //   const nearlyPage = pageList.value[0];
    //   if (!nearlyPage) { // 如果无已打开页面，返回首页
    //     await goToHome();
    //   } else { // 如果有其他已打开页面，则返回到已打开页面的第一个
    //     await navTo(nearlyPage.keyTo);
    //   }
    // } else { // 如果代表当前页面的缓存仍然有效
    //   if (+(billId || -1) < 1) { // 若未提供 billId 或 billId 小于 1, 则代表点击了取消保存
    //     const keyFrom = page.keyFrom;
    //     await navTo(keyFrom, JSON.parse(JSON.stringify(openPages.value.get(keyFrom)?.params))); // 用缓存的原始页面参数实例化目标页面，避免触发 router.afterEach 的参数一致性校验规则，导致重新渲染
    //   } else { // 若参数提供了有效的 billId 值，代表点击了保存
    //     const nextKey = identToNavName(page.bizIdent, 'view');
    //     await navTo(nextKey, { billId });
    //   }
    // }
    openPages.value.delete(keyBy);
  };

  /** 响应单据复制发起的导航
   *
   * @param cloneId 复制单据的来源ID
   */
  const navForClone = async (cloneId: number): Promise<void> => {
    const page = activePage.value;
    if (page) {
      const keyTo = identToNavName(page.bizIdent, 'add');
      const params: IRouterParam = { cloneId };
      await navTo(keyTo, params);

    } // 无需 else，因为 activePage 中已包含 page 为 undefined 的处理
  };

  /** 响应单据的下推
   *
   * @param params 下推所采用的业务参数
   * @returns
   */
  const navToPush = async (params: IRouterPushParams): Promise<void> => {
    if (params.fromDtlIds.length) {
      const keyTo = identToNavName(params.bizIdent.to, 'add');
      await navTo(keyTo, { pushParam: params });
    } else {
      Toaster.error(ERR_MSG.pushSourceIdsMiss);
    }
  };

  /** 响应单据的上引
   *
   * @param bizIdentTo 待上引的业务标识
   * @returns
   */
  const navToPull = async (bizIdentTo: string): Promise<void> => {
    const keyTo = identToNavName(bizIdentTo, 'list');
    await navTo(keyTo);
  };

  /** 跳转到同源序时簿，并关闭自身页面 */
  const backToList = async (): Promise<void> => {
    const page = activePage.value;
    if (page) {
      const keyTo = identToNavName(page.bizIdent, 'list');
      await navTo(keyTo);
      await removeTag({ key: page.keyTo, type: 'only' });
    } // 无需 else，因为 activePage 中已包含 page 为 undefined 的处理
  };
  // #endregion

  // #region 标签控制
  /** 切换活动标签
   *
   * @param keyTo 要切换的标签对应的路由 name 值
   */
  const switchTag = async (keyTo: string): Promise<void> => {
    const page = openPages.value.get(keyTo);
    const params = JSON.parse(JSON.stringify(page?.params || {}));
    if (Object.keys(params).length) {
      navTo(keyTo, JSON.parse(JSON.stringify(params)));
    } else {
      navTo(keyTo);
    }
  };

  /** 移除指定的页面标签
   *
   * @param opt { key: 待移除的标签对应的路由 name 值, type: 移除类型 }
   * @returns
   */
  const removeTag = async (opt: { key?: string, type: TCloseDirction }) => {
    if (opt.type === 'only') {
      const key = opt.key;
      if (key) {
        await removePolicy.only(key);
      }
    } else if (opt.type === 'all') {
      await goToHome();
    } else {
      const key = activeKey.value;
      if (key) {
        await removePolicy[opt.type]?.(key);
      }
    }
  };
  // #endregion

  // #region 缓存控制
  /** 读取缓存 */
  const getCache = () => {
    if (!cacheDone.value) {
      const cacheStr = sessionStorage.getItem(KEY_STORE_NAV) || '{}';
      const obj = JSON.parse(cacheStr);
      const objOpenPage = obj.openPages || {};
      openPages.value = new Map(Object.entries(objOpenPage));
      activeKey.value = obj.activeKey || '';
      cacheDone.value = true;
    }
  };

  /** 写入缓存 */
  const setCache = () => {
    const cache: {
      openPages: Record<string, IPageProfile>,
      activeKey: string
    } = {
      openPages: Object.fromEntries(openPages.value.entries()),
      activeKey: activeKey.value || ''
    };
    sessionStorage.setItem(KEY_STORE_NAV, JSON.stringify(cache));
  };
  // #endregion

  // #region 私有控制
  /** 将页面进行缓存 */
  const addPage = (to: RouteLocationNormalized, from: RouteLocationNormalized, params?: HistoryState) => {
    const { bizIdent, group, pathTitle = [] } = to.meta;
    const keyTo = to.name as string || '';
    const keyFrom = from.name as string || '';
    const { namezh, btns } = getTagName(to, from, params);
    if (namezh) {
      const pageInfo: IPageProfile = {
        keyTo,
        keyFrom,
        namezh,
        path: to.fullPath,
        bizIdent: bizIdent as string,
        group: group as TMenuGoup,
        breadCrumbs: pathTitle as string[],
        params: (params || {}) as IRouterParam,
        btns
      };

      openPages.value.set(to.name as string, pageInfo);
      // activeKey.value = keyTo;
    }
  };

  const navTo = async (keyTo: string, params?: IRouterParam) => {
    const to: RouteLocationRaw = { name: keyTo };
    if (params) {
      to.state = { params };
    }
    await shareRouter().push(to);
  };

  /** 销毁 */
  const destroy = async () => {
    await goToHome();
  };

  /** 跳转到首页 */
  const goToHome = async () => {
    await shareRouter().push({ name: HOME_KEY });
    // activeKey.value = HOME_KEY;
    openPages.value.clear();
    // activeKey.value = '';
    sessionStorage.removeItem(KEY_STORE_NAV);
  };

  const getNextKey = (key: string): string => {
    if (pageList.value.length < 2) return '';
    const idx = pageList.value.findIndex(v => v.keyTo === key);
    return pageList.value[!idx ? idx + 1 : idx - 1].keyTo;
    // const stateFrom = getStateByKey(key);
    // const page = openPages.value.get(key);
    // if (!page) return '';
    // const identFrom = page.bizIdent;
    // if (stateFrom === 'list') {
    //   const idx = pageList.value.findIndex((v: IPageProfile) => v.keyTo === key);
    //   if (idx > 0) {
    //     return pageList.value[idx - 1]?.keyTo || '';
    //   } else {
    //     return pageList.value[0]?.keyTo || '';
    //   }
    // } else {
      // const keyNext = identToNavName(identFrom, 'list');
      // if (openPages.value.has(keyNext)) return keyNext;
      // const keyFromOrigin = page.keyFrom;
      // if (openPages.value.has(keyFromOrigin)) return keyFromOrigin;
      // const arr = pageList.value;
      // if (arr.length === 1) return arr[0].keyTo;
      // if (arr.length > 1) return arr[arr.length - 1].keyTo;
      // return '';
    // }
  };

  /** 标签关闭策略 */
  const removePolicy: Record<Exclude<TCloseDirction, 'all'>, (key: string) => Promise<void>> = {
    only: async (key: string) => {
      if (openPages.value.size === 1) {
        await goToHome();
      } else {
        const keyNext = getNextKey(key);
        if (!keyNext) {
          await goToHome();
        } else {
          // const isAct = (key === activeKey.value);
          // openPages.value.delete(key);
          // if (isAct) {
          switchTag(keyNext);
          // }
        }
        openPages.value.delete(key);
      }
    },

    left: async (key: string) => {
      const keys = openPages.value.keys();
      for (const v of keys) {
        if (v === key) {
          break;
        } else {
          openPages.value.delete(v);
        }
      }
    },

    right: async (key: string) => {
      const keys = openPages.value.keys();
      let start = false;
      for (const v of keys) {
        if (start) {
          openPages.value.delete(v);
        }
        if (v === key) {
          start = true;
        }
      }
    },

    except: async (key: string) => {
      const keys = openPages.value.keys();
      for (const v of keys) {
        if (v !== key) {
          openPages.value.delete(v);
        }
      }
    }
  };
  // #endregion

  onMounted(async () => {
    getCache();
    shareRouter().afterEach(async (to: RouteLocationNormalized, from: RouteLocationNormalized, failure?: NavigationFailure | void) => {
      //  (!((failure?.from?.fullPath === '/') && (failure?.to?.fullPath === '/')) && isNavigationFailure(failure)) {
      if ((failure?.from?.fullPath !== failure?.to?.fullPath) && isNavigationFailure(failure)) {
        // useError(failure);
        return false;
      } else {
        const existPage = openPages.value.get(to.name as string);
        const params = history.state.params as IRouterParam;
        if (!existPage) {
          addPage(to, from, params);
        } else {
          const strParamsOld = getCompareParameStr(existPage.params);
          const strParamsNow = getCompareParameStr(params);
          if (strParamsOld !== strParamsNow) {
            openPages.value.delete(existPage.keyTo);
            await nextTick();
            addPage(to, from, params);
          }
        }
        if (params?.closeKey) {
          await removeTag({ key: params.closeKey, type: 'only' });
        }
      }
      activeKey.value = to.name as string;
      setCache();
    });
  });

  return {
    activeKey,
    pageList,
    pageInclude,
    breadCrumbs,
    getPage,
    navByMenu,
    navFromList,
    navFromCrud,
    navFromSave,
    navForClone,
    navToPush,
    navToPull,
    backToList,
    switchTag,
    removeTag,
    destroy
  };
};
